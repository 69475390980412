var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gantt-milestone" }, [
    _vm.data.done
      ? _c(
          "div",
          {
            staticClass: "content done",
            style: _vm.cptStyleDone,
            on: {
              mousedown: function($event) {
                $event.stopPropagation()
                return _vm.$emit("drag-start", $event)
              },
              click: function($event) {
                $event.stopPropagation()
                return _vm.$emit("focus-start", $event)
              },
              mouseenter: function($event) {
                $event.stopPropagation()
                return _vm.$emit("hover-start", $event)
              },
              mouseleave: function($event) {
                $event.stopPropagation()
                return _vm.$emit("hover-end")
              }
            }
          },
          [
            _c(
              "svg",
              {
                staticClass: "icon",
                attrs: { viewBox: "0 0 16 16", fill: "white" }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M5.536 11.175L2 7.639l1.497-1.497L7 9.642l6-6.28 1.497 1.497-7.464 7.814-1.497-1.497z"
                  }
                })
              ]
            )
          ]
        )
      : _c("div", {
          staticClass: "content",
          style: _vm.cptStyle,
          on: {
            mousedown: function($event) {
              $event.stopPropagation()
              return _vm.$emit("drag-start", $event)
            },
            click: function($event) {
              $event.stopPropagation()
              return _vm.$emit("focus-start", $event)
            },
            mouseenter: function($event) {
              $event.stopPropagation()
              return _vm.$emit("hover-start", $event)
            },
            mouseleave: function($event) {
              $event.stopPropagation()
              return _vm.$emit("hover-end")
            }
          }
        }),
    _c("div", { staticClass: "name", attrs: { title: _vm.data.name } }, [
      _vm._v(_vm._s(_vm.data.name))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }