var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gantt-group" },
    [
      _c(
        "div",
        {
          staticClass: "self-content",
          style: _vm.contentStyle,
          on: {
            mousedown: function($event) {
              $event.stopPropagation()
              return _vm.$emit("drag-start", $event)
            },
            click: function($event) {
              $event.stopPropagation()
              return _vm.$emit("focus-start", $event)
            },
            mouseenter: function($event) {
              $event.stopPropagation()
              return _vm.$emit("hover-start", $event)
            },
            mouseleave: function($event) {
              $event.stopPropagation()
              return _vm.$emit("hover-end")
            }
          }
        },
        [
          _c("gantt-progress", {
            attrs: {
              bus: _vm.bus,
              barColor: _vm.barColor,
              data: _vm.data,
              type: "group"
            },
            scopedSlots: _vm._u(
              [
                _vm.bus.chart && _vm.bus.chart.processContent
                  ? {
                      key: "processContent",
                      fn: function() {
                        return [
                          _c("GanttProgressContent", {
                            attrs: { bus: _vm.bus, data: _vm.data }
                          })
                        ]
                      },
                      proxy: true
                    }
                  : null
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c(
        "transition",
        [
          _c("gantt-layout", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showChildren,
                expression: "showChildren"
              }
            ],
            attrs: {
              data: _vm.data.children,
              bus: _vm.bus,
              activeRowPos: _vm.activeRowPos
            },
            on: {
              "update:activeRowPos": function($event) {
                _vm.activeRowPos = $event
              },
              "update:active-row-pos": function($event) {
                _vm.activeRowPos = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }