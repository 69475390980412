var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "gantt", staticClass: "bygo-gantt" },
    [
      _c("div", { staticClass: "gantt-oper-container" }, [
        _c(
          "div",
          { staticClass: "today-text", on: { click: _vm.scrollToToday } },
          [_vm._v("今天")]
        ),
        _c(
          "div",
          { staticClass: "view-type" },
          [
            _c(
              "el-dropdown",
              { attrs: { placement: "bottom-start" } },
              [
                _c("span", { staticClass: "el-dropdown-link" }, [
                  _vm._v(
                    "\n          按" +
                      _vm._s(
                        _vm.bus.viewType == _vm.ViewType.Day ? "天" : "周"
                      ) +
                      "查看"
                  ),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
                ]),
                _c(
                  "el-dropdown-menu",
                  {
                    staticClass: "oper-drop",
                    attrs: { slot: "dropdown" },
                    slot: "dropdown"
                  },
                  _vm._l(_vm.dateType, function(item) {
                    return _c(
                      "el-dropdown-item",
                      {
                        key: item.id,
                        class: { active: item.id == _vm.bus.viewType },
                        nativeOn: {
                          click: function($event) {
                            return _vm.setViewType(item.id)
                          }
                        }
                      },
                      [_vm._v(_vm._s(item.title))]
                    )
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("gantt-grid", {
        ref: "ganttGrid",
        attrs: {
          data: _vm.ganttGridData,
          bus: _vm.bus,
          groupColumn: _vm.bus.grid.groupColumn,
          columns: _vm.bus.grid.columns,
          activeRowPos: _vm.activeRowPos
        },
        on: {
          "update:activeRowPos": function($event) {
            _vm.activeRowPos = $event
          },
          "update:active-row-pos": function($event) {
            _vm.activeRowPos = $event
          }
        }
      }),
      _c("gantt-chart", {
        ref: "ganttChart",
        attrs: {
          options: _vm.options,
          data: _vm.ganttData,
          barColor: _vm.barColor,
          bus: _vm.bus,
          activeRowPos: _vm.activeRowPos,
          "drag-data": _vm.dragData,
          "resize-data": _vm.resizeData
        },
        on: {
          "update:activeRowPos": function($event) {
            _vm.activeRowPos = $event
          },
          "update:active-row-pos": function($event) {
            _vm.activeRowPos = $event
          }
        }
      }),
      _vm.activeRow.enable
        ? _c("div", {
            staticClass: "gantt-active-row-hover",
            style:
              "top: " +
              _vm.activeRow.top +
              "px; height: " +
              _vm.activeRow.height +
              "px"
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }