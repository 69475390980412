var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dataInPx.w > 0
    ? _c(
        _vm.component,
        _vm._b(
          {
            tag: "component",
            class: [
              "gantt-node",
              {
                moving: _vm.dragData.dragging || _vm.resizeData.resizing,
                "is-month-view": _vm.isMonthView,
                focusing: _vm.focusing
              },
              _vm.nodeState
            ],
            style: _vm.style,
            attrs: {
              bus: _vm.bus,
              barColor: _vm.cptBarColor,
              activeRowPos: _vm.activeRowPos
            },
            on: {
              "update:activeRowPos": function($event) {
                _vm.activeRowPos = $event
              },
              "update:active-row-pos": function($event) {
                _vm.activeRowPos = $event
              },
              "drag-start": _vm.onDragStart,
              "resize-start": _vm.onResizeStart,
              "focus-start": _vm.startFocus,
              "hover-start": _vm.startHover,
              "hover-end": _vm.endHover
            }
          },
          "component",
          _vm.$props,
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }