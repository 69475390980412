import { render, staticRenderFns } from "./gantt-chart.vue?vue&type=template&id=281b522f&scoped=true&"
import script from "./gantt-chart.vue?vue&type=script&lang=ts&"
export * from "./gantt-chart.vue?vue&type=script&lang=ts&"
import style0 from "./gantt-chart.vue?vue&type=style&index=0&id=281b522f&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "281b522f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Project\\we-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('281b522f')) {
      api.createRecord('281b522f', component.options)
    } else {
      api.reload('281b522f', component.options)
    }
    module.hot.accept("./gantt-chart.vue?vue&type=template&id=281b522f&scoped=true&", function () {
      api.rerender('281b522f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/plugins/gantt/components/gantt-chart.vue"
export default component.exports