var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "gantt-leaf",
      on: {
        mousedown: function($event) {
          $event.stopPropagation()
          return _vm.$emit("drag-start", $event)
        },
        click: function($event) {
          $event.stopPropagation()
          return _vm.$emit("focus-start", $event)
        },
        mouseenter: function($event) {
          $event.stopPropagation()
          return _vm.$emit("hover-start", $event)
        },
        mouseleave: function($event) {
          $event.stopPropagation()
          return _vm.$emit("hover-end")
        }
      }
    },
    [
      _vm.bus.enableResizeStart
        ? _c(
            "div",
            {
              staticClass: "drag-handle left",
              on: {
                mousedown: function($event) {
                  $event.stopPropagation()
                  return _vm.$emit("resize-start", "start", $event)
                }
              }
            },
            [_c("span", { staticClass: "icon" })]
          )
        : _vm._e(),
      _c("gantt-progress", {
        attrs: { bus: _vm.bus, barColor: _vm.barColor, data: _vm.data },
        scopedSlots: _vm._u(
          [
            _vm.bus.chart && _vm.bus.chart.processContent
              ? {
                  key: "processContent",
                  fn: function() {
                    return [
                      _c("GanttProgressContent", {
                        attrs: { bus: _vm.bus, data: _vm.data }
                      })
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      }),
      _vm.bus.enableResizeEnd
        ? _c(
            "div",
            {
              staticClass: "drag-handle right",
              on: {
                mousedown: function($event) {
                  $event.stopPropagation()
                  return _vm.$emit("resize-start", "end", $event)
                }
              }
            },
            [_c("span", { staticClass: "icon" })]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }